

























import Vue from 'vue'

export default Vue.extend({
  name: 'Index',

  data: () => ({
   
  }),
  created(){
    this.$vuetify.theme.dark=true;
  }
})
